body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Poppins', sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
  padding: 1rem;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.swiper-button-next,
.swiper-button-prev {
  color: #ff6200 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.swiper-button-next {
  right: 3rem !important;
}

.swiper-button-prev {
  left: 3rem !important;
}

.swiper-pagination-bullet {
  height: 0.75rem !important;
  width: 0.75rem !important;
}

.swiper-pagination-bullet-active {
  background-color: #ff6200 !important;
}

@media (max-width: 768px) {
  .swiper-pagination-bullet {
    height: 0.65rem !important;
    width: 0.65rem !important;
  }
}

@media (max-width: 640px) {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 22px !important;
  }

  .swiper-button-next {
    right: 1.5rem !important;
  }

  .swiper-button-prev {
    left: 1.5rem !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.extra-thin-scrollbar::-webkit-scrollbar {
  width: 2.5px;
}

input {
  caret-color: #ff6200;
}
